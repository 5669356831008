export const environment = {
  production: false,
  settingsServiceBasePath: 'https://api.dev.smartobjx.com/settings-demo',
  accessServiceBasePath:'https://api.dev.smartobjx.com/access-demo',
  structureServiceBasePath: 'https://api.dev.smartobjx.com/structures-demo',
  SubcriptionService:'https://so-preproduction-subscriptionmanagement-east.azurewebsites.net/',
  marketServiceBasePath: 'https://market-demo.smartobjx.com',//https://market.dev.smartobjx.com/api/
  publicBasePath: 'https://api.dev.smartobjx.com/public',
  clientAPITest: 'https://nomadapp-as-preprod-ussc-west.azurewebsites.net/api/api/Mockup/location/structure',

};
